export enum Action {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
  LIST = 'list',
  MANAGE = 'manage',
  DUPLICATE = 'duplicate',
  ENABLE = 'enable',
  EXPORT = 'export',
  ACCESS = 'access',
  VIEW = 'view',
}

export const CRUDL = [Action.CREATE, Action.READ, Action.UPDATE, Action.DELETE, Action.LIST]

export enum Subject {
  /**
   * Organization
   */
  ORGANIZATION_GENERAL = 'ORGANIZATION_GENERAL',
  ORGANIZATION_TEAM = 'ORGANIZATION_TEAM',
  ORGANIZATION_TEAM_OWNER = 'ORGANIZATION_TEAM_OWNER',
  ORGANIZATION_DOMAIN = 'ORGANIZATION_DOMAIN',
  ORGANIZATION_PHISHING_DOMAIN = 'ORGANIZATION_PHISHING_DOMAINS',
  ORGANIZATION_INTEGRATIONS = 'ORGANIZATION_INTEGRATIONS',
  ORGANIZATION_NOTIFICATIONS = 'ORGANIZATION_NOTIFICATIONS',
  ORGANIZATION_SECURITY = 'ORGANIZATION_SECURITY',
  ORGANIZATION_SUBSCRIPTION = 'ORGANIZATION_SUBSCRIPTION',
  ORGANIZATION_CAMPAIGNS_SETTINGS = 'ORGANIZATION_CAMPAIGNS_SETTINGS',
  VERIFIED_DOMAIN = 'VERIFIED_DOMAIN',
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_GENERAL = 'ACCOUNT_GENERAL',
  ACCOUNT_SECURITY = 'ACCOUNT_SECURITY',
  SETTING = 'SETTING',
  SETTING_REPORT = 'SETTING_REPORT',
  SETTING_WHITELIST = 'SETTING_WHITELIST',
  EMPLOYEE = 'EMPLOYEE',
  GROUP = 'GROUP',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_STATISTICS = 'DASHBOARD_STATISTICS',
  AI_SCENARIO = 'AI_SCENARIO',
  SCENARIO = 'SCENARIO',
  SCENARIO_RECOMMENDATION = 'SCENARIO_RECOMMENDATION',
  QUOTA = 'QUOTA',
  CAMPAIGN = 'CAMPAIGN',
  CYBER_THREAT_INTELLIGENCE = 'CYBER_THREAT_INTELLIGENCE',
  CYBER_THREAT_INTELLIGENCE_FEATURE_WALL = 'CYBER_THREAT_INTELLIGENCE_FEATURE_WALL',
  EVENT = 'EVENT',
  PRICING = 'PRICING',
  ERROR = 'ERROR',
  AUTH = 'AUTH',
  ADVANCED_SIMULATIONS = 'ADVANCED_SIMULATIONS',
  SMISHING = 'SMISHING',
  VISHING = 'VISHING',
  DISPATCHER = 'DISPATCHER',
  MFA_ENROLL = 'MFA_ENROLL',
  TRAINING_AWARENESS = 'TRAINING_AWARENESS',
  TRAINING_AWARENESS_FEATURE_WALL = 'TRAINING_AWARENESS_FEATURE_WALL',
  TRAINING_AWARENESS_LESSON = 'TRAINING_AWARENESS_LESSON',
  PARTNER_ACCOUNT = 'PARTNER_ACCOUNT',

  /**
   * Partner
   */
  PARTNER_BILLING = 'PARTNER_BILLING',
  PARTNER_ORGANIZATIONS = 'PARTNER_ORGANIZATIONS',
  PARTNER_ORGANIZATION_SCENARIO = 'PARTNER_ORGANIZATION_SCENARIO',
  PARTNER_SECURITY = 'PARTNER_SECURITY',
  PARTNER_SETTINGS = 'PARTNER_SETTINGS',
  PARTNER_TEAM = 'PARTNER_TEAM',
  PARTNER_TEAM_OWNER = 'PARTNER_TEAM_OWNER',

  /**
   * Tenantless
   */
  ORGANIZATIONS = 'ORGANIZATIONS',
}
